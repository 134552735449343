import React from 'react'
import { toLower, trim, replace } from 'lodash'

import { ExtraLargeContentContainer } from 'src/components/Layout/Structure'
import { ReactComponent as AssetImageKailuaBeach } from 'src/assets/location-kailuabeach.svg'
import { ReactComponent as AssetImageKailuaTown } from 'src/assets/location-kailuatown.svg'
import { ReactComponent as AssetImageKapolei } from 'src/assets/location-kapolei.svg'
import { ReactComponent as AssetImageWaimanalo } from 'src/assets/location-waimanalo.svg'

import {
  IntroContainer,
  Content,
  ImageOahuAspectRatio,
  TextContent,
  Heading,
  Text,
  styledSVG,
} from './Intro.styled'

export const Intro = ({ ...props }) => {
  const locationName = trim(toLower(replace(props.locationName, ' ', '')))
  return (
    <IntroContainer>
      <ExtraLargeContentContainer>
        <Content>
          <ImageOahuAspectRatio x={166} y={89}>
            {locationName === 'kapolei' && (
              <AssetImageKapolei className={styledSVG} />
            )}
            {locationName === 'kailuatown' && (
              <AssetImageKailuaTown className={styledSVG} />
            )}
            {locationName === 'kailuabeach' && (
              <AssetImageKailuaBeach className={styledSVG} />
            )}
            {locationName === 'waimanalo' && (
              <AssetImageWaimanalo className={styledSVG} />
            )}
          </ImageOahuAspectRatio>
          <TextContent>
            <Heading>{props.introTitle}</Heading>
            <Text>{props.introBody}</Text>
          </TextContent>
        </Content>
      </ExtraLargeContentContainer>
    </IntroContainer>
  )
}
