import styled from 'react-emotion'

import t from 'src/theme'
import { Link } from 'src/components/Shared/Link/Link'
import AssetTextureWood from 'src/assets/texture-wood.jpg'

export const HoursEventsContainer = styled.div`
  color: ${t.c.white};
  background-color: #222;
  background-image: url(${AssetTextureWood});
  background-repeat: repeat;
  background-size: 100% auto;
  text-align: center;
  padding: ${t.s(3, 1)};

  ${t.mq.m} {
    padding: ${t.s(4, 1)};
  }
`

export const ContentContainer = styled.div`
  ${t.mq.m} {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
`

export const Content = styled.div`
  flex: 1 1 0px;
  margin-bottom: ${t.s(3)};
  font-weight: 600;
  ${t.mq.m} {
    margin-bottom: 0;
    margin-right: ${t.s(2)};
  }

  &:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
`

export const BoxedContent = styled(Content)`
  border-radius: ${t.r(-8)};
  border: 1px solid ${t.c.white};
  padding: ${t.s(3, 1)};
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const Heading = styled.h2`
  font-family: ${t.ff.sansHeadline};
  margin: ${t.s(2)} 0 ${t.s(0)};

  &:first-child {
    margin-top: 0;
  }
`

export const Text = styled.div`
  font-family: ${t.ff.monoBody};
  font-size: ${t.f(0)};
  line-height: ${t.lh.copy};
  margin: 0 0 ${t.s(1)};

  &:last-child {
    margin-bottom: 0;
  }

  div {
    p {
      margin-bottom: 0;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`

export const StyledLink = styled(Link)`
  color: inherit;
  font-family: ${t.ff.monoBody};
  font-size: ${t.f(0)};
  font-weight: 900;
  text-decoration: none;
  text-transform: uppercase;
`
