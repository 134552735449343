import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { get, head, inRange, toInteger, forEach, filter } from 'lodash'
import { format } from 'date-fns'
import { boolean } from 'boolean'

import { nodes } from 'src/helpers'
import { Layout } from 'src/components/Layout/Layout'
import { Title } from 'src/components/Location/Title/Title'
import { Intro } from 'src/components/Location/Intro/Intro'
import { Menu } from 'src/components/Location/Menu/Menu'
import { PrintableMenu } from 'src/components/Location/PrintableMenu/PrintableMenu'
import { HoursEvents } from 'src/components/Location/HoursEvents/HoursEvents'
import { LocationCarousel } from 'src/components/Location/LocationCarousel/LocationCarousel'
import { PrintController } from 'src/components/Shared/PrintController/PrintController'

class LocationTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeMenu: 'deli',
      liveOpenStatus: null,
      restaurantHours: null,
    }
  }

  setActiveMenu = option => {
    this.setState({ activeMenu: option })
  }

  setDefaultMenu = () => {
    this.setState({ activeMenu: 'deli' })
  }

  componentDidMount() {
    const LocationHours = get(
      this.props,
      'data.prismicLocation.data.restaurant_hours'
    )
    const currentDayOfWeek = format(new Date(), 'dddd')
    const currentHourOfDay = format(new Date(), 'H')
    const currentMinuteOfDay = format(new Date(), 'm')
    const currentHourInDecimal =
      (toInteger(currentHourOfDay) * 60 + toInteger(currentMinuteOfDay)) / 60

    forEach(LocationHours, hour => {
      if (hour.day_of_week === currentDayOfWeek) {
        inRange(currentHourInDecimal, hour.open, hour.close)
          ? this.setState({ liveOpenStatus: 'Open' })
          : this.setState({ liveOpenStatus: 'Closed' })
        this.setState({ restaurantHours: hour.readable_hours })
      }
    })
  }

  render() {
    const location = get(this.props.data, 'prismicLocation')
    const locationData = get(location, 'data')
    const menuData = get(
      this.props.data,
      'prismicLocation.data.menu.document.data'
    )

    const events = nodes(get(this.props.data, 'allPrismicEvent'))
    const eventsForLocation = filter(
      events,
      x => get(x, 'data.location.document.uid') === get(location, 'uid')
    )
    const firstEventForLocation = head(eventsForLocation)

    return (
      <Layout
        activePageName="Locations"
        resetMenu={this.setDefaultMenu}
        showHeaderFooterOnPrint={false}
      >
        <Helmet title={get(locationData, 'location_name')} />
        <PrintController showOnPrint={false}>
          <Title
            name={get(locationData, 'location_name')}
            phone={get(locationData, 'phone_number')}
            address={get(locationData, 'location_address1.text')}
            directions={get(locationData, 'google_maps_link.url')}
            onlineOrderHref={get(locationData, 'order_online_link.url')}
            allowCallForTakeOut={boolean(
              get(locationData, 'allow_call_for_take_out')
            )}
            reservationHref={get(locationData, 'reservation_link.url')}
            allowReservation={boolean(get(locationData, 'allow_reservations'))}
          />
          <LocationCarousel
            images={get(locationData, 'carousel_images')}
            openSign="Today's Hours"
            openHours={this.state.restaurantHours}
          />
          <Intro
            locationName={get(locationData, 'location_name')}
            introTitle={get(locationData, 'location_intro_title')}
            introBody={get(locationData, 'location_intro_body.text')}
          />
          <HoursEvents
            hours={get(locationData, 'hours.html')}
            phone={get(locationData, 'phone_number')}
            email={get(locationData, 'email')}
            event={firstEventForLocation}
          />
          <Menu
            menu={menuData}
            activeMenu={this.state.activeMenu}
            setActiveMenu={this.setActiveMenu}
          />
        </PrintController>
        <PrintController showOnScreen={false} showOnPrint={true}>
          <PrintableMenu data={menuData} />
        </PrintController>
      </Layout>
    )
  }
}

export default LocationTemplate

export const query = graphql`
  query LocationTemplate($id: String!) {
    prismicLocation(id: { eq: $id }) {
      ...PrismicMenu
      id
      uid
      data {
        location_name
        location_address1 {
          text
        }
        carousel_images {
          image {
            alt
            fluid(maxWidth: 1200) {
              ...GatsbyPrismicImageFluid
            }
          }
        }
        location_intro_title
        location_intro_body {
          text
        }
        location_card_image {
          fluid(maxWidth: 800) {
            ...GatsbyPrismicImageFluid
          }
        }
        location_card_link {
          location_card_link_text
          location_card_link_url {
            url
          }
        }
        hours {
          html
        }
        phone_number
        email
        google_maps_link {
          url
        }
        restaurant_hours {
          day_of_week
          open
          close
          readable_hours
        }
        order_online_link {
          url
        }
        allow_call_for_take_out
        reservation_link {
          url
        }
        allow_reservations
      }
    }
    allPrismicEvent(sort: { fields: [data___event_date], order: DESC }) {
      edges {
        node {
          id
          data {
            event_name
            event_date
            event_time
            location {
              document {
                ... on PrismicLocation {
                  uid
                  data {
                    location_name
                  }
                }
              }
            }
            event_description {
              text
            }
          }
        }
      }
    }
  }
`
