import React from 'react'
import Masonry from 'react-masonry-component'
import { get, head, drop, toLower, trim, isEmpty, capitalize } from 'lodash'

import { MediumContentContainer } from 'src/components/Layout/Structure'
import { MenuCategory } from './MenuCategory'
import {
  MenuBodyContainer,
  Hours,
  CategoryContainer,
  MenuDisclaimer,
} from './MenuBody.styled'

export const MenuBody = ({
  menu,
  menuTitle,
  menuDisclaimer,
  activeMenu,
  hasMoreThanOneMenu,
}) => {
  // Changing the order of slices will break this code
  // Keep MenuInformation slice always at the top

  const menuInformation = get(head(menu), 'primary')
  const subMenus = drop(menu)
  // TODO: This menu system should generally be refactored. This comparison is
  // expecting the CMS field to always either be "Dinner" or "Deli".
  const isActive = trim(toLower(menuTitle)) === trim(toLower(activeMenu))
  const oneColumnMenu = subMenus.length === 1
  const masonryOptions = {
    transitionDuration: 0,
  }

  const menuCategories = subMenus.map((item, index) => (
    <MenuCategory
      key={get(item, 'primary.menu_title') + index}
      introText={get(item, 'primary.menu_section_intro')}
      menuBody={get(item, 'primary.menu_body.html')}
      className="image-element-class"
      oneColumnMenu={oneColumnMenu}
    >
      {get(item, 'primary.menu_title')}
    </MenuCategory>
  ))

  return (
    <MenuBodyContainer
      show={isActive}
      reduceMargin={menu}
      hasMoreThanOneMenu={hasMoreThanOneMenu}
    >
      <h3>{capitalize(get(menuInformation, 'menu_title')) + ' Menu'}</h3>
      <Hours>
        {!isEmpty(get(menuInformation, 'menu_hours.text')) &&
          get(menuInformation, 'menu_hours.text')}
      </Hours>

      <CategoryContainer>
        <Masonry
          className={'my-gallery-class'} // default ''
          disableImagesLoaded={false} // default false
          updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
          options={masonryOptions}
        >
          {menuCategories}
        </Masonry>
      </CategoryContainer>
      <MediumContentContainer>
        <MenuDisclaimer>{menuDisclaimer}</MenuDisclaimer>
      </MediumContentContainer>
    </MenuBodyContainer>
  )
}
