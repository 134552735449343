import React from 'react'
import { get, map } from 'lodash'

import { Image } from 'src/components/Shared/Image'
import { Carousel } from 'src/components/Shared/Carousel/Carousel'
import { ExtraLargeContentContainer } from 'src/components/Layout/Structure'
import LocationCallout from 'src/assets/burst.svg'
import {
  Container,
  LocationHoursContainer,
  OpenSign,
} from './LocationCarousel.styled'

export const LocationCarousel = ({ images, openSign, openHours, ...props }) => {
  return (
    <Container {...props}>
      <ExtraLargeContentContainer>
        <Carousel isShort={true} context={'location'}>
          {map(
            images,
            (image, index) =>
              get(image, 'image.fluid') && (
                <Image
                  key={get(image, 'image.alt') + index}
                  alt={get(image, 'image.alt')}
                  fluid={get(image, 'image.fluid')}
                />
              )
          )}
        </Carousel>
        <LocationHoursContainer>
          <img src={LocationCallout} alt="" />
          <OpenSign>
            <div>{openSign}</div>
            <div>{openHours}</div>
          </OpenSign>
        </LocationHoursContainer>
      </ExtraLargeContentContainer>
    </Container>
  )
}
