import React from 'react'
import { graphql } from 'gatsby'
import { get, isArray, pickBy, keys, head, toArray } from 'lodash'

import { windowExists } from 'src/helpers'
import {
  LargeContentContainer,
  ExtraLargeContentContainer,
} from 'src/components/Layout/Structure'
import { Button } from 'src/components/Shared/Button/Button'
import { MenuDropdown } from './MenuDropdown'
import { MenuBody } from './MenuBody'
import { MenuContainer, MenuDescription } from './Menu.styled'

export const Menu = ({ menu, activeMenu, setActiveMenu }) => {
  const menus = pickBy(menu, item => {
    return isArray(item)
  })
  const hasMoreThanOneMenu = keys(menus).length > 1
  const printWindow = event => {
    event.preventDefault()
    if (windowExists()) window.print()
  }

  return (
    <MenuContainer id="menu">
      <ExtraLargeContentContainer>
        <h2>Menu</h2>
        <LargeContentContainer>
          <MenuDescription>{get(menu, 'description.text')}</MenuDescription>
        </LargeContentContainer>
        <>
          <MenuDropdown
            menus={menus}
            setActiveMenu={setActiveMenu}
            show={hasMoreThanOneMenu}
          />
          {toArray(menus).map(menu => (
            <MenuBody
              key={get(head(menu), 'primary.menu_title')}
              menuTitle={get(head(menu), 'primary.menu_title')}
              menuDisclaimer={get(head(menu), 'primary.menu_disclaimer.text')}
              activeMenu={activeMenu}
              menu={menu}
              hasMoreThanOneMenu={hasMoreThanOneMenu}
            />
          ))}
        </>
        <Button onClick={printWindow}>Print Menu</Button>
      </ExtraLargeContentContainer>
    </MenuContainer>
  )
}

export const query = graphql`
  fragment PrismicMenu on PrismicLocation {
    data {
      menu {
        document {
          ... on PrismicMenu {
            data {
              location
              description {
                text
              }
              deli {
                ... on PrismicMenuDeliInformation {
                  slice_type
                  primary {
                    menu_title
                    menu_hours {
                      text
                    }
                    menu_disclaimer {
                      text
                    }
                  }
                }
                ... on PrismicMenuDeliMenu {
                  primary {
                    menu_title
                    menu_section_intro
                    menu_body {
                      html
                    }
                  }
                }
              }
              dinner {
                ... on PrismicMenuDinnerInformation {
                  slice_type
                  primary {
                    menu_title
                    menu_hours {
                      text
                    }
                    menu_disclaimer {
                      text
                    }
                  }
                }
                ... on PrismicMenuDinnerMenu {
                  primary {
                    menu_title
                    menu_section_intro
                    menu_body {
                      html
                    }
                  }
                }
              }
              wine {
                ... on PrismicMenuWineInformation {
                  slice_type
                  primary {
                    menu_title
                    menu_hours {
                      text
                    }
                    menu_disclaimer {
                      text
                    }
                  }
                }
                ... on PrismicMenuWineMenu {
                  primary {
                    menu_title
                    menu_section_intro
                    menu_body {
                      html
                    }
                  }
                }
              }
              pau_hana {
                ... on PrismicMenuPauHanaInformation {
                  slice_type
                  primary {
                    menu_title
                    menu_hours {
                      text
                    }
                    menu_disclaimer {
                      text
                    }
                  }
                }
                ... on PrismicMenuPauHanaMenu {
                  primary {
                    menu_title
                    menu_section_intro
                    menu_body {
                      html
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
