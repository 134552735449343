import React from 'react'
import { get, replace } from 'lodash'

import { HTMLContent } from 'src/components/Shared/HTMLContent'
import { LargeContentContainer } from 'src/components/Layout/Structure'
import {
  HoursEventsContainer,
  ContentContainer,
  Content,
  BoxedContent,
  Heading,
  Text,
  StyledLink,
} from './HoursEvents.styled'

export const HoursEvents = ({ children, event, ...props }) => {
  return (
    <HoursEventsContainer>
      <LargeContentContainer>
        <ContentContainer>
          <Content>
            <Heading>Hours</Heading>
            <Text>
              <HTMLContent html={props.hours} />
            </Text>
            <Heading>Contact</Heading>
            <Text>
              <a href={'tel://' + replace(props.phone, /\s|\(|\)|-/g, '')}>
                {props.phone}
              </a>
              <br />
              <a href={'mailto:' + props.email}>{props.email}</a>
            </Text>
          </Content>
          {event && (
            <BoxedContent>
              <Heading>Upcoming Event</Heading>
              <Text>
                <strong>
                  {get(event, 'data.event_name')}
                  <br />
                  {get(event, 'data.event_date')} &middot;
                  {' ' + get(event, 'data.event_time')} &middot;
                  {' ' +
                    get(event, 'data.location.document.data.location_name')}
                </strong>
              </Text>
              <Text>{get(event, 'data.event_description.text')}</Text>
              <StyledLink to="/#events">View more events</StyledLink>
            </BoxedContent>
          )}
        </ContentContainer>
      </LargeContentContainer>
    </HoursEventsContainer>
  )
}
