import styled from 'react-emotion'
import { css } from 'emotion'

import t from 'src/theme'
import { AspectRatio } from 'src/components/Shared/AspectRatio/AspectRatio'

export const IntroContainer = styled.div`
  background-color: ${t.c.white};
  font-family: ${t.ff.monoBody};
  font-size: ${t.f(-1)};
  line-height: ${t.lh.copy};
  padding: ${t.s(3, 2)};

  ${t.mq.m} {
    padding: ${t.s(6, 2)};
  }
`

export const Content = styled.div`
  ${t.mq.m} {
    align-items: center;
    display: flex;
  }
`

export const ImageOahuAspectRatio = styled(AspectRatio)`
  margin: 0 auto ${t.s(2)};
  max-width: ${t.s(9)};
  width: 75%;

  ${t.mq.m} {
    flex: 1 1 0px;
    margin-bottom: 0;
    margin-right: ${t.s(2)};
    max-width: 100%;
  }
`
export const styledSVG = css`
  display: block;
  fill: #9f9b90;
  height: 100%;
  width: 100%;
`

export const TextContent = styled.div`
  text-align: center;

  ${t.mq.m} {
    flex: 1 1 0px;
    text-align: left;
    margin-bottom: ${t.s(2)};
  }
`

export const Heading = styled.h2`
  font-size: ${t.f(3)};
  color: ${t.c.green};
  ${t.mq.m} {
    font-size: ${t.f(4)};
  }
`

export const Text = styled.p`
  font-size: ${t.f(-1)};
  line-height: ${t.lh.wide};

  ${t.mq.m} {
    margin: 0;
    font-size: ${t.f(0)};
  }
`
