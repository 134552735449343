import styled from 'react-emotion'

import t from 'src/theme'
import { Link } from 'src/components/Shared/Link/Link'

export const Container = styled.div`
  background-color: ${t.c.white};
  padding: ${t.s(3, 1, 2)};
  text-align: center;

  ${t.mq.m} {
    text-align: left;
  }
`

export const Content = styled.div`
  ${t.mq.m} {
    align-items: flex-end;
    justify-content: space-between;
    display: flex;
  }
`
export const HeadingContainer = styled.div`
  ${t.mq.m} {
    display: flex;
    align-items: center;
  }
`

export const Heading = styled.h2`
  color: ${t.c.lightGreen};
  display: block;
  font-size: ${t.f(5)};
  letter-spacing: 0.05em;
  margin: 0 0 ${t.s(0)};
  text-transform: uppercase;

  ${t.mq.m} {
    margin: 0 ${t.s(1)} 0 0;
    display: inline-block;
  }
`
export const StyledLink = styled(Link)`
  background-color: ${p => (p.reservationAccepted ? t.c.buttonPink : t.c.gray)};
  border-radius: 2px;
  color: white;
  display: inline-block;
  font-family: ${t.ff.sansHeadline};
  font-size: ${t.f(-1)};
  font-weight: 600;
  letter-spacing: 0.1em;
  margin: 0 0 ${t.s(1)} 0;
  padding: ${t.s(-2)} ${t.s(-1)};
  text-decoration: none;

  ${t.mq.m} {
    margin: 0;
  }
`

export const TakeoutLink = styled(Link)`
  font-family: ${t.ff.sansHeadline};
  font-size: ${t.f(-1)};
  font-weight: 600;
  letter-spacing: 0.1em;
  color: ${t.c.gray};
  margin-left: ${t.s(-1)};
  display: ${p => (p.reservationAccepted ? 'none' : 'inline-block')};
`

export const Details = styled.div`
  color: ${t.c.black};
  font-family: ${t.ff.monoBody};
  font-size: ${t.f(0)};
  line-height: ${t.lh.title};
  p {
    margin: 0;
  }
  p:nth-child(2) {
    margin-top: 5px;
  }
  ${t.mq.m} {
    display: flex;
    flex-direction: column;
    text-align: right;

    p {
      order: 1;
    }

    br {
      display: none;
    }
  }
`

export const DirectionsLink = styled(Link)`
  color: ${t.c.gray};
  display: block;
  font-size: ${t.f(-1)};
  font-weight: bold;
  letter-spacing: 0.1em;
  margin-top: ${t.s(-2)};
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    color: ${t.c.green};
  }

  ${t.mq.m} {
    order: 0;
    margin-top: 0;
    margin-bottom: ${t.s(-2)};
  }
`
