import React from 'react'

import { ExtraLargeContentContainer } from 'src/components/Layout/Structure'
import {
  Container,
  Content,
  HeadingContainer,
  Heading,
  Details,
  DirectionsLink,
  StyledLink,
  TakeoutLink,
} from './Title.styled'

export const Title = ({
  name,
  phone,
  address,
  directions,
  onlineOrderHref,
  allowCallForTakeOut,
  reservationHref,
  allowReservation,
  ...props
}) => (
  <Container {...props}>
    <ExtraLargeContentContainer>
      <Content>
        <HeadingContainer>
          <Heading>{name}</Heading>
          {onlineOrderHref && (
            <StyledLink to={onlineOrderHref} target="_blank">
              ORDER ONLINE
            </StyledLink>
          )}
          {allowReservation &&
            reservationHref && (
              <StyledLink to={reservationHref} target="_blank">
                RESERVE NOW
              </StyledLink>
            )}
          {allowCallForTakeOut && (
            <TakeoutLink to={`tel://${phone.replace(/[^0-9.]/g, '')}`}>
              CALL FOR TAKEOUT
            </TakeoutLink>
          )}
        </HeadingContainer>
        <Details>
          <p>{address}</p>
          <p>{phone}</p>
          <DirectionsLink to={directions} target="_blank">
            Get Directions
          </DirectionsLink>
        </Details>
      </Content>
    </ExtraLargeContentContainer>
  </Container>
)
