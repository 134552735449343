import React from 'react'
import { keys, replace } from 'lodash'

import {
  MenuDropdownContainer,
  DropdownButtonOutline,
  DropdownButton,
} from './MenuDropdown.styled'

export const MenuDropdown = ({ menus, show, setActiveMenu }) => (
  <MenuDropdownContainer show={show}>
    {keys(menus).map(menu => (
      <DropdownButtonOutline>
        <DropdownButton onClick={e => setActiveMenu(replace(menu, /_/g, ' '))}>
          {replace(menu, /_/g, ' ') + ' menu'}
        </DropdownButton>
      </DropdownButtonOutline>
    ))}
  </MenuDropdownContainer>
)
