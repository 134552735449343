import styled from 'react-emotion'
import t from 'src/theme'

export const MenuDropdownContainer = styled.div`
  width: 100%;
  justify-content: space-around;
  display: ${p => (p.show ? 'flex' : 'none')};
  flex-direction: column;
  border: solid 2px ${t.c.gray};
  padding: calc(1rem / 3);
  border-radius: 3px;

  ${t.mq.m} {
    flex-direction: row;
    border: none;
    padding: 0;
    border-radius: 0;
  }
`

export const DropdownButtonOutline = styled.div`
  ${t.mq.m} {
    display: inline-block;
    border: solid 2px ${t.c.gray};
    padding: calc(1rem / 3);
    border-radius: 3px;
    margin-right: ${t.s(1)};
    width: calc(33.33% - (${t.s(1)} * 2));
    &:last-child {
      margin-right: 0;
    }
  }
`

export const DropdownButton = styled.div`
  background-color: ${t.c.white};
  display: inline-block;
  padding: ${t.s(1)} 0;
  font-size: ${t.f(2)};
  font-family: ${t.ff.monoBody};
  color: ${t.c.gray};
  text-transform: uppercase;
  border: solid 2px ${t.c.gray};
  border-bottom: 0;
  border-radius: 0;
  font-weight: 400;
  width: 100%;
  height: 100%;
  transition: background-color ${t.t} ease-in-out;
  &:hover {
    background-color: ${t.c.gray};
    color: ${t.c.white};
    cursor: pointer;
  }
  ${DropdownButtonOutline}:last-child & {
    border-bottom: solid 2px ${t.c.gray};
  }
  ${t.mq.m} {
    border-bottom: solid 2px ${t.c.gray};
    border-radius: 3px;
  }
`

// export const StyledButton = styled(Button)`
//   display: inline-block;
//   font-family: ${t.ff.monoBody};
//   a {
//     text-decoration: none;
//   }
//   &:hover {
//     cursor: pointer;
//     background-color: ${t.c.green};
//     a {
//       color: ${t.c.white};
//       text-decoration: none;
//     }
//   }
// `
