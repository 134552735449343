import React from 'react'
import { get, map, pick, find, filter } from 'lodash'

import {
  Container,
  Logo,
  Heading,
  Description,
  Menus,
  Menu,
  MenuHeading,
  MenuHours,
  MenuCategories,
  MenuCategory,
  MenuCategoryHeading,
  MenuCategoryContent,
  MenuDisclaimer,
} from './PrintableMenu.styled'

export const PrintableMenu = ({ data, ...props }) => {
  const menus = pick(data, ['deli', 'dinner', 'pau_hana', 'wine'])

  return (
    <Container {...props}>
      <Logo />
      <Heading>{get(data, 'location')} Menu</Heading>
      <Description>{get(data, 'description.text')}</Description>
      <Menus>
        {map(menus, (menu, idx) => {
          const menuInformation = find(menu, x =>
            get(x, '__typename', '').match(/Information$/)
          )
          const menuCategories = filter(menu, x =>
            get(x, '__typename', '').match(/Menu$/)
          )

          return (
            <Menu key={idx}>
              <MenuHeading>
                {get(menuInformation, 'primary.menu_title')}
              </MenuHeading>
              <MenuHours>
                {get(menuInformation, 'primary.menu_hours.text')}
              </MenuHours>
              <MenuCategories>
                {map(menuCategories, (category, idx) => (
                  <MenuCategory key={idx}>
                    <MenuCategoryHeading>
                      {get(category, 'primary.menu_title')}
                    </MenuCategoryHeading>
                    <MenuCategoryContent
                      dangerouslySetInnerHTML={{
                        __html: get(category, 'primary.menu_body.html'),
                      }}
                    />
                  </MenuCategory>
                ))}
              </MenuCategories>
              <MenuDisclaimer>
                {get(menuInformation, 'primary.menu_disclaimer.text')}
              </MenuDisclaimer>
            </Menu>
          )
        })}
      </Menus>
    </Container>
  )
}
