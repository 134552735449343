import styled from 'react-emotion'
import t from 'src/theme'

export const MenuBodyContainer = styled.div`
  padding: ${p => (p.hasMoreThanOneMenu ? '4rem 1rem' : '1rem 1rem 4rem 1rem')};
  display: ${p => (p.show ? 'block' : 'none')};
  text-align: center;

  h2 {
    color: ${t.c.green};
    font-size: ${t.f(4)};
    margin-bottom: ${t.s(3)};
    font-family: ${t.ff.sansHeadline};
    margin-bottom: ${t.s(0)};
  }
`

export const Hours = styled.div`
  color: ${t.c.gray};
  font-size: ${t.f(0)};
  margin: 0 auto;
  margin-bottom: ${t.s(3)};
  font-family: ${t.ff.monoBody};
`

export const CategoryContainer = styled.div`
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: space-between;
`

export const MenuDisclaimer = styled.div`
  font-family: ${t.ff.monoBody};
  font-size: ${t.f(0)};
  margin-top: ${t.s(2)};
  font-style: italic;
`
