import styled from 'react-emotion'

import t from 'src/theme'
import { HTMLContent } from 'src/components/Shared/HTMLContent'
import { TapeHeading } from 'src/components/Shared/TapeHeading/TapeHeading'

export const MenuCategoryContainer = styled.div`
  width: 100%;
  padding: 0 ${t.s(1)} ${t.s(2.5)} ${t.s(1)};

  ${t.mq.m} {
    width: ${p => (p.oneColumnMenu ? '100%' : 'calc(100%/3)')};
  }
`

export const Wrapper = styled.div``

export const Heading = styled(TapeHeading)`
  color: ${t.c.gray};
  display: inline-block;
  font-family: ${t.ff.monoBody};
  font-size: ${t.f(1)};
  font-weight: 500;
  letter-spacing: 0.05em;
  margin: ${t.s(1)} 0;
  text-transform: uppercase;
  width: ${p => (p.oneColumn ? '50%' : '100%')};
`

export const StyledHTMLContent = styled(HTMLContent)`
  p {
    display: block;
    color: ${t.c.gray};
    margin: 0;
    padding: 0;
    font-size: ${t.f(-0.5)};
    font-family: ${t.ff.monoBody};

    strong {
      color: ${t.c.black};
      display: block;
      font-family: ${t.ff.sansHeadline};
      font-size: ${t.f(0)};
      font-weight: 500;
      margin-top: ${t.s(2)};
    }
  }
`

export const IntroText = styled.div`
  font-family: ${t.ff.monoBody};
  font-size: ${t.f(-0.5)};
  color: ${t.c.darkGray};
  margin-top: ${t.s(0)};
  font-style: italic;
`
