import styled from 'react-emotion'

import t from 'src/theme'
import { ReactComponent as AssetLogoSVG } from 'src/assets/logo.svg'

export const Container = styled.div`
  font-family: ${t.ff.monoBody};
  color: ${t.c.black};
  font-size: ${t.s(-1)};
`

export const Logo = styled(AssetLogoSVG)`
  display: block;
  width: 25%;
  fill: ${t.c.green};
  margin: 0 auto 0.25in;
`

export const Heading = styled.h2`
  font-size: ${t.f(2)};
  margin: 0 0 0.125in;
  text-align: center;
`

export const Description = styled.p`
  font-style: italic;
  width: 75%;
  margin: 0 auto ${t.s(-2)};
  text-align: center;
`

export const Menus = styled.div``

export const Menu = styled.div`
  &:not(:first-child) {
    page-break-inside: avoid;
  }
`

export const MenuHeading = styled.h3`
  font-family: ${t.ff.sansHeadline};
  font-size: ${t.f(1)};
  text-align: center;
  text-transform: uppercase;
  margin-bottom: ${t.s(-2)};
`

export const MenuHours = styled.p`
  text-align: center;
  margin-bottom: ${t.s(-4)};
`

export const MenuCategories = styled.div`
  margin-bottom: 0.125in;
`

export const MenuCategory = styled.div`
  break-inside: avoid;
`

export const MenuCategoryHeading = styled.h4`
  font-size: ${t.f(0)};
`

export const MenuCategoryContent = styled.div`
  columns: 3;

  p {
    margin-bottom: ${t.s(-1)};
    page-break-inside: avoid;
  }

  strong {
    display: block;
    font-family: ${t.ff.sansHeadline};
    font-weight: 500;
    margin-bottom: -${t.s(-2)};
  }
`

export const MenuDisclaimer = styled.p`
  font-style: italic;
  margin: 0 auto;
  text-align: center;
  width: 75%;
`
