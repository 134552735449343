import styled from 'react-emotion'
import t from 'src/theme'

export const Container = styled.div`
  background-color: ${t.c.white};
  padding: 0 ${t.s(1)};
`

export const LocationHoursContainer = styled.div`
  img {
    position: absolute;
    width: 21%;
    top: 6%;
    left: 4%;
    display: none;
    ${t.mq.x} {
      display: block;
    }
  }
`

export const OpenSign = styled.div`
  div {
    position: absolute;
    color: ${t.c.white};
    font-family: ${t.ff.script};
    font-weight: 900;
    transform: rotate(-5deg);
  }
  div:nth-child(1) {
    top: 15%;
    left: 9.5%;
    font-size: ${t.f(3)};
    display: none;
    ${t.mq.l} {
      font-size: ${t.f(2)};
      display: block;
    }
  }

  div:nth-child(2) {
    top: 25%;
    left: 8%;
    font-weight: 900;
    display: none;
    font-size: ${t.f(1.5)};

    ${t.mq.l} {
      top: 21%;
      display: block;
    }
  }
`
