import styled from 'react-emotion'

import t from 'src/theme'
import AssetTextureMuffinCoffee from 'src/assets/svg-muffin-coffee.svg'
import AssetInnerTubeLady from 'src/assets/innertube.svg'

export const MenuContainer = styled.div`
  background-color: ${t.c.lightCream};
  background-image: none;

  ${t.mq.m} {
    background-image: url(${AssetTextureMuffinCoffee}),
      url(${AssetInnerTubeLady});
    background-repeat: no-repeat, no-repeat;
    background-position: -2% 50%, 99% 32%;
    background-size: 14%, 6%;
  }

  padding: ${t.s(6)} ${t.s(1)};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  h2 {
    color: ${t.c.green};
    font-size: ${t.f(5)};
    margin-bottom: ${t.s(0)};
    font-family: ${t.ff.sansHeadline};
  }

  h3 {
    color: ${t.c.lightGreen};
    font-family: ${t.ff.sansHeadline};
    font-size: ${t.f(5)};
    letter-spacing: 0.05em;
    margin-bottom: ${t.s(0)};
    text-transform: uppercase;
  }

  p {
    color: ${t.c.gray};
    font-size: ${t.f(0)};
    margin: 0 auto;

    font-family: ${t.ff.monoBody};
  }
`

export const MenuDescription = styled.div`
  color: ${t.c.gray};
  font-family: ${t.ff.monoBody};
  font-size: ${t.f(0)};
  line-height: ${t.lh.wide};
  margin: 0 auto ${t.s(2.5)};
`
