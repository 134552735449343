import React from 'react'

import {
  MenuCategoryContainer,
  Wrapper,
  StyledHTMLContent,
  Heading,
  IntroText,
} from './MenuCategory.styled'

export const MenuCategory = ({
  menuBody,
  oneColumnMenu,
  introText,
  children,
}) => {
  return (
    <MenuCategoryContainer oneColumnMenu={oneColumnMenu}>
      <Heading maxTilt={2} oneColumn={oneColumnMenu}>
        {children}
      </Heading>
      <IntroText>{introText}</IntroText>
      <Wrapper>
        <StyledHTMLContent html={menuBody} />
      </Wrapper>
    </MenuCategoryContainer>
  )
}
